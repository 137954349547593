<template>
  <section class="about-me">
    <p>
      I have an interest in solving hard problems and making things work.
      I'm currently employed at
      <a
        href="https://www.trans-system.com/"
      >Trans-System, Inc</a> working on a variety
      of projects ranging from a custom softphone hosted within
      <a
        href="https://www.electronjs.org/"
      >electron</a> to a mobile document scanning solution
      utilizing the computer vision tech of
      <a
        href="https://opencv.org/"
      >OpenCV</a>
      . Besides {{ (new Date()).getFullYear() - 2015 }}+ years of professional experience, I also come jam packed with humorous quips
      and jolly good times.
    </p>

    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex md4 xs12 class="skills">
          <v-card class="fill-height js">
            <v-layout column justify-space-between fill-height class="px-3 pt-3 pb-1">
              <p>
                Its ubiquity, approachableness, and capability to power systems end-to-end has made JavaScript
                an excellent language to learn and leverage, and makes it my language of choice whenever possible.
              </p>

              <v-spacer />

              <v-layout justify-start>
                <v-flex xs2 class="d-flex align-end">
                  <v-img
                    alt="VueJS"
                    contain
                    class="tool-icon"
                    src="@/assets/img/logos/vuejs.png"
                    title="VueJS"
                  />
                </v-flex>
                <v-flex xs2 class="d-flex align-end">
                  <v-img
                    alt="Vuetify"
                    contain
                    class="tool-icon"
                    src="@/assets/img/logos/vuetify.png"
                    title="Vuetify"
                  />
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </v-flex>

        <v-flex md4 xs12 class="skills">
          <v-card class="fill-height csharp">
            <v-layout column justify-space-between fill-height class="px-3 pt-3 pb-1">
              <p class="white--text">
                I'm actively involved in developing Windows services and ASP.NET production code
                leveraging .NET technologies.
              </p>

              <v-spacer />

              <v-layout justify-start>
                <v-flex xs3 class="d-flex align-end">
                  <v-img
                    alt="ASP.NET MVC"
                    contain
                    class="tool-icon"
                    src="@/assets/img/logos/asp.net-mvc.png"
                    title="ASP.NET MVC"
                  />
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </v-flex>

        <v-flex md4 xs12 class="skills">
          <v-card class="fill-height sql">
            <v-layout column justify-space-between fill-height class="px-3 pt-3 pb-1">
              <p class="white--text">
                I'm mostly familiar with SQL Server, with a
                <i>smidgen</i> of PostgreSQL experience.
                I enjoy pairing up DB change detection with websockets for realtime updates.
              </p>

              <v-spacer />

              <v-layout justify-start>
                <v-flex xs3 class="d-flex align-end">
                  <v-img
                    alt="Microsoft SQL Server"
                    contain
                    class="tool-icon"
                    src="@/assets/img/logos/sql-server.png"
                    title="Microsoft SQL Server"
                  />
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container grid-list-md class="pt-0">
      <v-layout row wrap justify-end align-center>
        <v-flex md4 xs12>
          <h3 class="headline mb-3 text-right">Familiar Implements</h3>
        </v-flex>

        <v-flex md1 xs3 v-for="i in icons" :key="i.path">
          <v-img
            contain
            class="tool-icon"
            :alt="i.description"
            :src="require(`@/assets/img/logos/${i.path}.png`)"
            :title="i.description"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    icons: [
      { path: 'git', description: 'Git' },
      { path: 'github', description: 'GitHub' },
      { path: 'node', description: 'NodeJS' },
      { path: 'webpack', description: 'Webpack' },
      { path: 'babel', description: 'BabelJS' },
      { path: 'vscode', description: 'Visual Studio Code' },
      { path: 'azuredatastudio', description: 'Azure Data Studio' }
    ]
  })
}
</script>

<style scoped>
.skills {
  min-height: 300px;
  font-size: 15px;
}

.js {
  background-color: #f5de19;
  background-image: url('~@/assets/img/logos/js.png');
  background-position: bottom right;
  background-size: 60%;
}

.csharp {
  background-color: #a27cdd;
  background-image: url('~@/assets/img/logos/c_sharp.png');
  background-position: bottom right;
  background-size: 40%;
}

.sql {
  background-color: #2d88cc;
  background-image: url('~@/assets/img/logos/sql.png');
  background-position: bottom right;
  background-size: 35%;
}

.tool-icon {
  height: 50px;
}

@media only screen and (min-width: 640px) and (max-width: 960px) {
  .js {
    background-size: 30%;
  }
  .csharp {
    background-size: 22%;
  }
  .sql {
    background-size: 20%;
  }
}
</style>
